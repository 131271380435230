// import logo from './logo.svg';
import './App.sass';
import Gallery from './Gallery.js';

function App() {
  const element = <Gallery name="testing stuff" />;

  return (
    <div className="App">
      <body>
        <h1>Lili Hung | <span class="text-italic">lilisart</span></h1>
        {/* <br/> */}
        {/* <h2></h2> */}
        {/* <br/> */}
        <hr/>
        <p>
          這個網頁尚未完成，想看跟多作品，請參觀我的IG~
        </p>
        <p>
          This website is currently being built. Please visit my instagram profile for more illustrations.
        </p>
        <p>•••</p>
        <h3>Contact | 聯絡</h3>
        <p>
          Mail: lilihung.art@gmail.com<br/>
          Instagram: <a href='https://www.instagram.com/lilisart/'>@lilisart</a><br/>

        </p>
        {/* <a href=''>contact</a> |  <a href=''>illustration</a> | <a href=''>sticker sets</a> */}
        {/* <a href=''>shop</a> */}
        {element}
        
      </body>
    </div>
  );
}

export default App;
