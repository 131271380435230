import React from "react";
import ghostdeer from "./static/gallery/ghost-deer.png";
import woodcreature from "./static/gallery/wood-creature.png";
import './App.sass';
// import 'style.sass';

class Gallery extends React.Component {
  picture(picurl){

    return (
      <span class='gal-pic'>
        <img src={picurl} alt='alttext' />
      </span>
      );
  } 


  render() {
    return (
      <div>
        {this.picture(ghostdeer)}
        {this.picture(woodcreature)}
        {/* {this.picture(ghostdeer)}
        {this.picture(woodcreature)}
        {this.picture(ghostdeer)}
        {this.picture(woodcreature)}
        {this.picture(ghostdeer)}
        {this.picture(woodcreature)} */}
      </div>

    );
  }
}

export default Gallery;